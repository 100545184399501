import { appReducer } from '.';
import { APP_REDUCER_NAME } from './constants';

type ReducerState = ReturnType<typeof appReducer>;

type State = {
  [APP_REDUCER_NAME]: ReducerState;
};

export const selectShowSidebar = (state: State) =>
  state[APP_REDUCER_NAME].showSidebar;

export const selectSearchActive = (state: State) =>
  state[APP_REDUCER_NAME].searchActive;

export const selectUserData = (state: State) =>
  state[APP_REDUCER_NAME].userData;

export const selectSuccessReg = (state: State) =>
  state[APP_REDUCER_NAME].successReg;
