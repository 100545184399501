import { createReducer } from '@reduxjs/toolkit';

import { InitialState } from '@/types';

import {
  setSearchActive,
  setShowSidebar,
  setUserData,
  setSuccessReg,
} from './actions';

const initialState: InitialState = {
  showSidebar: false,
  searchActive: false,
  userData: null,
  successReg: false,
};

export const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(setShowSidebar, (state, action) => {
    state.showSidebar = action.payload;
  });

  builder.addCase(setSearchActive, (state, action) => {
    state.searchActive = action.payload;
  });

  builder.addCase(setUserData, (state, action) => {
    state.userData = action.payload;
  });

  builder.addCase(setSuccessReg, (state, action) => {
    state.successReg = action.payload;
  });
});
