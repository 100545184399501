import { createAction } from '@reduxjs/toolkit';

import { ACTIONS } from './constants';
import { UserDataType } from '@/types';

export const setShowSidebar = createAction<boolean>(ACTIONS.SHOW_SIDEBAR_MENU);

export const setSearchActive = createAction<boolean>(ACTIONS.SET_SEARCH_ACTIVE);

export const setUserData = createAction<UserDataType>(ACTIONS.SET_USER_DATA);

export const setSuccessReg = createAction<boolean>(ACTIONS.SET_SUCCESS_REG);
