export const BACKEND_URL = process.env.BACKEND_URL;
export const SPACE = process.env.SPACE;
export const TICKET_FRAME_URL = process.env.TICKET_FRAME_URL as string;

export const MOBILE_RESOLUTION = 435;
export const TABLET_RESOLUTION = 1025;

export const EMPTY_META = {
  title: 'Остров мечты',
  description: undefined,
  image: undefined,
  canonical: undefined,
  h1: undefined,
  keywords: undefined,
  robots: undefined,
  text: undefined,
};

export const PASSWORD_REGEX =
  /^^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()-_=+{};:,.<>?]{8,}$/;

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;

export const PHONE_REGEX = /\+\d\s?\(\d{3}\)\s?\d{3}-\d{2}-\d{2}/;

export const PARK_RAZVLECHENIY_CODE = 'park';
export const DREAM_BEACH_CLUB_CODE = 'beach-club';
export const PROMENAD_CODE = 'promenade';
export const DREAM_GAME_CENTER_CODE = 'game-center';
export const SOUTH_LANDSCAPE_CODE = 'south-landscape';
export const CONCERT_HALL_CODE = 'concert-hall';
export const SUMMER_CINEMA_CODE = 'summer-cinema';

export const darkHeaderPages = [
  '/restaurants',
  '/shops',
  '/trades',
  '/entertainments',
  `/objects/${DREAM_BEACH_CLUB_CODE}`,
  `/objects/${PROMENAD_CODE}`,
  `/objects/${DREAM_GAME_CENTER_CODE}`,
  `/objects/${SOUTH_LANDSCAPE_CODE}`,
  `/objects/${PARK_RAZVLECHENIY_CODE}`,
];
